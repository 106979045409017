<template>
  <q-responsive :ratio="ratio">
    <q-card class="bg-dark flex flex-center relative-position"
      ><logo-moon-boobs color="primary" style="width: 64%" />
      <div class="absolute-top flex flex-center">
        <circle-text-header
          v-if="active"
          :header="data.title"
          fill="primary"
          text-class="text-h6"
        />
      </div>
      <div class="absolute-bottom-right">
        <text-label :label="t('giftCard.label')" />
      </div>
    </q-card>
  </q-responsive>
</template>

<script setup>
import { i18n } from "src/boot/i18n";
import LogoMoonBoobs from "src/components/logo/LogoMoonBoobs.vue";
import CircleTextHeader from "src/components/circleText/CircleTextHeader.vue";
import TextLabel from "src/components/text/TextLabel.vue";

defineOptions({ name: "GiftCardVariants" });

const props = defineProps({
  active: { type: Boolean, default: true },
  ratio: { type: Number, default: 0.6667 },
  data: { type: Object, default: () => {} },
});

const { t } = i18n.global;
</script>
