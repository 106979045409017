import { ref, computed } from "vue";

export function useIntersection(thresholdPercentage, root = null) {
  const percent = ref(0);
  const thresholds = [];
  for (let i = 0; i <= 1.0; i += 0.01) {
    thresholds.push(i);
  }

  const options = {
    handler(entry) {
      const val = (entry.intersectionRatio * 100).toFixed(0);
      if (percent.value !== val) {
        percent.value = val;
      }
    },
    cfg: {
      threshold: thresholds,
      root: root || null,
    },
  };

  const isIntersecting = computed(() => percent.value > thresholdPercentage);

  return { options, isIntersecting };
}
