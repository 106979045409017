import { ref, computed } from "vue";
import { axios } from "boot/axios";

export const useAura = (props, emits) => {
  const aura = ref("");
  const auraHash = ref("");
  const palette = ref({});
  const auraInput = computed(() => props.image?.aura || null);
  const computedAura = computed(() => aura.value);
  const computedAuraHash = computed(() => auraHash.value);
  const computedPalette = computed(() => palette.value);

  const fetchAura = async () => {
    if (!auraInput.value) return;
    try {
      const url = new URL(auraInput.value);
      const imageName = url.pathname.split("/").pop();
      const outputFilename = `${Date.now()}_${imageName}`;
      const requestOptions = {
        inputPath: auraInput.value,
        outputFilename,
      };
      const response = await axios.post(`/api/${props.api}`, requestOptions);
      if (response.data.imageUrl) {
        aura.value = response.data.imageUrl;
      }
      if (response.data.base64) {
        auraHash.value = `data:image/png;base64,${response.data.base64}`;
      }
      if (response.data.palette) {
        palette.value = response.data.palette;
      }
      emits("brightnessChange", {
        level: response.data.level,
        palette: response.data.palette,
      });
    } catch (error) {}
  };

  return {
    auraInput,
    computedAura,
    computedAuraHash,
    computedPalette,
    fetchAura,
  };
};
