import { computed } from "vue";
import { Screen } from "quasar";

export function useResponsiveImageWidth(mdWidth) {
  const computedWidth = computed(() => {
    const hrWidth = mdWidth * 2;
    if (Screen.xs) {
      return hrWidth / 2;
    } else if (Screen.sm) {
      return hrWidth * 0.75;
    } else if (Screen.md) {
      return hrWidth;
    } else if (Screen.lg) {
      return hrWidth * 2;
    } else if (Screen.xl) {
      return hrWidth * 2.5;
    } else {
      return hrWidth;
    }
  });

  return computedWidth;
}
