<template>
  <q-card
    bordered
    class="relative-position shadow-2"
    ref="motionRef"
    @mouseenter="onMouseEnter()"
    @mouseleave="onMouseLeave()"
  >
    <blur-background :blur="blur" style="opacity: 0.6" />
    <q-btn
      :aria-label="ariaLabel"
      class="q-py-md"
      :icon="icon"
      :size="size"
      square
      :to="to || null"
    />
  </q-card>
</template>

<script setup>
import { computed, nextTick, onMounted, ref, watch } from "vue";
import { useMotion } from "@vueuse/motion";
import { storeToRefs } from "pinia";
import { usePreferencesStore } from "src/stores/preferences";
import BlurBackground from "./BlurBackground.vue";

defineOptions({ name: "BlurButton" });

const props = defineProps({
  ariaLabel: { type: String, default: "button" },
  blur: { type: String, default: "xs" },
  hoverable: { type: Boolean, default: true },
  icon: { type: String, default: "circle" },
  size: { type: String, default: "md" },
  to: { type: [Boolean, Object], default: false },
});

const preferencesStore = usePreferencesStore();
const { reducedMotion } = storeToRefs(preferencesStore);
const motionRef = ref(null);
let motionInstance = null;
const computedReducedMotion = computed(() => reducedMotion.value);

const createMotionInstance = () => {
  return useMotion(motionRef, {
    initial: {
      y: 100,
      opacity: 0,
    },
    enter: {
      y: 0,
      opacity: 1,
      transition: {
        type: "spring",
        stiffness: 350,
        damping: 20,
        delay: 50,
        onComplete: () => {
          motionInstance.apply("levitate");
        },
      },
    },
    levitate: {
      y: 5,
      transition: {
        duration: 2000,
        repeat: Infinity,
        ease: "easeInOut",
        repeatType: "mirror",
      },
    },
    hover: {
      scale: 1.1,
      transition: {
        type: "spring",
        stiffness: 300,
        damping: 20,
      },
    },
    leave: {
      scale: 1,
      transition: {
        type: "spring",
        stiffness: 300,
        damping: 20,
      },
    },
  });
};

const applyAnimations = () => {
  if (!computedReducedMotion.value && motionInstance) {
    motionInstance.apply("enter");
  } else if (motionInstance) {
    motionInstance.stop();
  }
};

const onMouseEnter = () => {
  if (!computedReducedMotion.value && motionInstance && props.hoverable) {
    motionInstance.apply("hover");
  }
};

const onMouseLeave = () => {
  if (!computedReducedMotion.value && motionInstance && props.hoverable) {
    motionInstance.apply("leave");
  }
};

watch(computedReducedMotion, () => {
  if (motionInstance) {
    applyAnimations();
  } else if (!computedReducedMotion.value) {
    motionInstance = createMotionInstance();
    applyAnimations();
  }
});

onMounted(async () => {
  await nextTick();
  if (!computedReducedMotion.value) {
    motionInstance = createMotionInstance();
    applyAnimations();
  }
});
</script>
