export function useImageFinder() {
  const findImages = (data) => {
    let images = [];

    if (data.image) {
      images.push({ ...data.image });
    }

    if (data.products && data.products.edges) {
      data.products.edges.forEach((productEdge) => {
        if (
          productEdge.node &&
          productEdge.node.images &&
          productEdge.node.images.edges
        ) {
          productEdge.node.images.edges.forEach((imageEdge) => {
            if (imageEdge.node) {
              images.push({ ...imageEdge.node });
            }
          });
        }
      });
    }

    return images;
  };

  return { findImages };
}
