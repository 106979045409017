<template>
  <div
    class="absolute-full no-pointer-events"
    :class="[`bg-${color}-fade`, maskClass]"
    :style="maskStyles"
  >
    <slot></slot>
  </div>
</template>

<script setup>
import { computed } from "vue";

defineOptions({ name: "BlurMask" });

const props = defineProps({
  blur: { type: String, default: "sm" },
  color: { type: String, default: "primary" },
  mask: { type: [String, Boolean], default: "x" },
  maskStartX: { type: String, default: "0%" },
  maskEndX: { type: String, default: "16%" },
  maskInnerEndX: { type: String, default: "84%" },
  maskStartY: { type: String, default: "0%" },
  maskEndY: { type: String, default: "16%" },
  maskInnerEndY: { type: String, default: "84%" },
  opacity: { type: Number, default: 0.9 },
});

const maskClass = computed(() => {
  return `mask-overflow-${props.mask}`;
});

const maskStyles = computed(() => {
  return `
    opacity: ${props.opacity};
    --mask-start-x: ${props.maskStartX};
    --mask-end-x: ${props.maskEndX};
    --mask-inner-end-x: ${props.maskInnerEndX};
    --mask-start-y: ${props.maskStartY};
    --mask-end-y: ${props.maskEndY};
    --mask-inner-end-y: ${props.maskInnerEndY};
  `;
});
</script>
