<template>
  <div class="atropos my-atropos overflow-visible" ref="atropos">
    <div class="atropos-scale">
      <div class="atropos-rotate">
        <div class="atropos-inner">
          <image-card
            v-if="
              data.articles.edges.length && data.articles.edges[0]?.node?.image
            "
            :height="height ? height : null"
            :data="data.articles.edges[0].node.image"
            :ratio="ratio"
            :width="width ? width : null"
          />
          <router-link
            class="absolute-full"
            data-atropos-offset="5"
            :to="{ name: 'blog', params: { blog: data.handle } }"
          >
            <div class="absolute-center">
              <circle-text-page-title :title="data.title" />
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { nextTick, onMounted, ref } from "vue";
import { useQuasar } from "quasar";
import CircleTextPageTitle from "src/components/circleText/CircleTextPageTitle.vue";
import ImageCard from "src/components/image/ImageCard.vue";
import Atropos from "atropos";
import "atropos/css";

defineOptions({ name: "BlogCard" });

const props = defineProps({
  height: { type: [Boolean, String], default: false },
  data: { type: Object, default: () => {} },
  loading: { type: String, default: "lazy" },
  ratio: { type: Number, default: 0.6667 },
  updateAura: { type: Boolean, default: false },
  view: { type: String, default: "default" },
  width: { type: [Boolean, String], default: false },
});

const $q = useQuasar();
const atropos = ref(null);

onMounted(async () => {
  if ($q.platform.is.mobile) return;
  await nextTick();
  const myAtropos = Atropos({
    el: atropos.value,
    rotateTouch: false,
    rotateXMax: 2.5,
    rotateXMax: 2.5,
  });
});
</script>
