<template>
  <div class="relative-position text-shadow">
    <div
      :class="[textSize, textColor]"
      class="items-center relative-position row"
      ref="containerRef"
      style="margin: 0"
    >
      <div
        v-if="isNewArrival || isOnSale"
        class="items-center row relative-position"
      >
        <div class="relative-position q-mr-xs">
          <q-skeleton
            :animation-speed="3500"
            class="absolute-full no-pointer-events"
            height="16px"
            square
          />
          <div class="q-gutter-xs">
            <q-badge
              v-if="isNewArrival"
              :class="textColor"
              class="relative-position shadow-11 text-lowercase text-small"
            >
              {{
                abbreviated
                  ? t("product.badges.newArrivals.abbreviated")
                  : t("product.badges.newArrivals.label")
              }}
            </q-badge>
            <q-badge
              v-if="isOnSale"
              :class="textColor"
              class="relative-position shadow-11 text-lowercase text-small"
            >
              {{ t("product.label.sale") }}
            </q-badge>
          </div>
        </div>
        <div>${{ price }}</div>
      </div>
      <div v-else>
        <span class="q-pa-xs">${{ price }}</span>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { storeToRefs } from "pinia";
import { i18n } from "src/boot/i18n";
import { useFormatPrice } from "src/composables/product/useFormatPrice";
import { useColorStore } from "src/stores/color";

defineOptions({ name: "VariantPrice" });

const props = defineProps({
  abbreviated: { type: Boolean, default: false },
  tags: { type: Array, default: () => [] },
  tags: { type: Array, default: () => [] },
  textSize: { type: String, default: "text-caption" },
  variant: { type: Object, default: () => {} },
});

const { t } = i18n.global;
const { formatPrice } = useFormatPrice();
const colorStore = useColorStore();
const { textColor } = storeToRefs(colorStore);
const containerRef = ref(null);

const { price, compareAtPrice, isOnSale, isUnavailable } = formatPrice(
  props.variant?.node || props.variant
);

const isNewArrival = () => {
  return props.tags.includes("New Arrivals");
};
</script>
