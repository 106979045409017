<template>
  <div>
    <swiper-container
      init="false"
      ref="swiper"
      style="z-index: 0"
      @mouseenter="onEnter"
      @mouseleave="onLeave"
    >
      <swiper-slide
        v-for="(slide, index) in slides"
        class="overflow-visible relative-position"
        :key="`slide-${index}`"
        :style="slideStyle"
      >
        <div
          class="relative-position"
          :class="containerClass"
          :style="slideStyle"
        >
          <content-card
            :active="swiperRealIndex === index"
            class="full-height swiper-zoom-target"
            :content="slide.node || slide"
            :loading="eager ? (index === 0 ? 'eager' : 'lazy') : 'lazy'"
            :padding="padding && swiperRealIndex === index"
            :slide-style="slideStyle"
            :to="slidesTo"
            :type="slide.node?.__typename || slide.__typename || slidesType"
            :width="slideWidth"
            @slide-change="(slide) => onContentCardSlideChange(slide)"
          />
        </div>
      </swiper-slide>
    </swiper-container>
    <div v-if="navigation" class="q-ml-md swiper-button-prev">
      <blur-button
        aria-label="previous"
        :class="prev"
        class="prev-btn"
        :color="color"
        :icon="t('icons.arrowPrev')"
        mask="oval"
        ref="prevBtn"
        :size="navigationBtnSize"
      />
    </div>
    <div v-if="navigation" class="q-mr-md swiper-button-next">
      <blur-button
        aria-label="next"
        :class="next"
        class="next-btn"
        :color="color"
        :icon="t('icons.arrowNext')"
        ref="nextBtn"
        :size="navigationBtnSize"
      />
    </div>
    <div v-if="zoom" class="absolute-bottom-left q-ma-sm" style="z-index: 1">
      <blur-button
        aria-label="zoom"
        :color="color"
        :icon="t('icons.zoomIn')"
        :size="zoomBtnSize"
        @click="onZoomClick()"
      />
    </div>
  </div>
</template>

<script setup>
import { computed, inject, nextTick, onMounted, ref, watch } from "vue";
import { storeToRefs } from "pinia";
import { debounce, useQuasar, useTimeout } from "quasar";
import { i18n } from "src/boot/i18n";
import { useAuraStore } from "src/stores/aura";
import { useDialogStore } from "src/stores/dialog";
import BlurButton from "src/components/blur/BlurButton.vue";
import ContentCard from "src/components/content/ContentCard.vue";

const props = defineProps({
  auto: { type: Boolean, default: false },
  autoHeight: { type: Boolean, default: false },
  autoplay: {
    type: [Object, Boolean],
    default: () => ({
      delay: 600,
      disableOnInteraction: true,
      pauseOnMouseEnter: false,
      stopOnLastSlide: false,
      waitForTransition: false,
    }),
  },
  centeredSlides: { type: Boolean, default: true },
  centeredSlidesBounds: { type: Boolean, default: false },
  creativeEffect: {
    type: Object,
    default: () => ({
      next: {
        translate: ["100%", 0, 0],
        rotate: [0, 0, 22],
      },
      prev: {
        translate: ["-100%", 0, 0],
        rotate: [0, 0, -22],
      },
      progressMultiplier: 1,
      shadow: true,
      shadowPerProgress: false,
      perspective: true,
    }),
  },
  color: { type: String, default: "primary" },
  cssMode: { type: Boolean, default: false },
  eager: { type: Boolean, default: false },
  effect: { type: String, default: "fade" },
  fade: {
    type: Object,
    default: () => ({
      crossFade: true,
    }),
  },
  freeMode: {
    type: [Object, Boolean],
    default: () => ({
      enabled: true,
      sticky: true,
    }),
  },
  hoverable: { type: Boolean, default: false },
  hovering: { type: Boolean, default: false },
  id: { type: String, default: "defaultSwiperId" },
  indexDebounce: { type: Number, default: 250 },
  keyboard: { type: Boolean, default: false },
  loop: { type: Boolean, default: false },
  loopAddBlankSlides: { type: Boolean, default: false },
  loopAdditionalSlides: { type: Number, default: 0 },
  loopPreventsSliding: { type: Boolean, default: false },
  mousewheel: {
    type: [Object, Boolean],
    default: () => ({
      forceToAxis: true,
      invert: false,
      releaseOnEdges: false,
    }),
  },
  navigation: {
    type: [Object, Boolean],
    default: () => ({
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    }),
  },
  navigationBtnSize: { type: String, default: "md" },
  next: { type: String, default: "next" },
  overflow: { type: Boolean, default: true },
  padding: { type: Boolean, defaut: false },
  pagination: {
    type: Object,
    default: () => ({
      el: ".swiper-pagination",
      type: "progressbar",
    }),
  },
  prev: { type: String, default: "prev" },
  rewind: { type: Boolean, default: true },
  slideChangeTimeout: { type: Number, default: 1200 },
  slides: { type: Array, default: () => new Array(4).fill({}) },
  slidesPerView: { type: [String, Number], default: "auto" },
  slideHeight: { type: [Boolean, String], default: false },
  slidesTo: { type: [Boolean, Object], default: false },
  slidesType: { type: String, default: "image" },
  slideWidth: { type: [Boolean, String], default: false },
  soundDebounce: { type: Number, default: 0 },
  spaceBetween: { type: Number, default: 0 },
  speed: { type: Number, default: 600 },
  zoom: { type: [Boolean, Object], default: false },
  zoomBtnSize: { type: String, default: "md" },
});

const emits = defineEmits([
  "auraResponse",
  "colorValue",
  "progress",
  "setTransition",
  "slideChange",
]);

const { registerTimeout } = useTimeout();

const HOVER_DELAY = 250;
const $q = useQuasar();
const { t } = i18n.global;
const bus = inject("bus");
const auraStore = useAuraStore();
const { auraRequest, requestId } = storeToRefs(auraStore);
const dialogStore = useDialogStore();
const { topDialog } = storeToRefs(dialogStore);
const hover = ref(false);
const hoverTimeout = ref(null);
const swiper = ref(null);
const swiperRealIndex = ref(0);
const nextBtn = ref(null);
const prevBtn = ref(null);
const computedHovering = computed(() => props.hovering);
const computedSlides = computed(() => props.slides);

const slideStyle = computed(() => {
  let styles = {};
  if (props.slideHeight) {
    styles.height = props.slideHeight;
  }
  if (props.slideWidth) {
    styles.width = props.slideWidth;
  }
  return styles;
});

const containerClass = computed(() => {
  if (props.zoom) {
    return "swiper-zoom-container";
  } else if (!props.slideWidth && !props.slideHeight) {
    return "fit";
  } else {
    return "";
  }
});

const onContentCardSlideChange = (slide) => {
  emits("slideChange", slide);
};

const onEnter = () => {
  if ($q.platform.is.mobile) return;
  hoverTimeout.value = setTimeout(() => {
    hover.value = true;
  }, HOVER_DELAY);
};

const onLeave = () => {
  if ($q.platform.is.mobile) return;
  clearTimeout(hoverTimeout.value);
  hover.value = false;
};

const onZoomClick = () => {
  swiper.value.swiper.autoplay.stop();
  dialogStore.show("imageZoom", {
    images: computedSlides.value,
    index: swiperRealIndex.value,
  });
  bus.emit("auraSoundShortLow");
};

const updateRealIndex = debounce((index) => {
  nextTick(() => {
    swiperRealIndex.value = index;
  });
}, props.indexDebounce);

const emitSlideChange = (swiper) => {
  if (swiper?.autoplay?.running === false)
    if (props.slides[swiper.realIndex])
      emits("slideChange", props.slides[swiper.realIndex]);
};

const handleSlideChange = (swiper) => {
  registerTimeout(() => {
    emitSlideChange(swiper);
  }, props.slideChangeTimeout);
};

const useSlideSound = debounce(() => {
  let sound = "swiperSlideSound";
  switch (props.effect) {
    case "coverflow":
    case "flip":
    case "creative":
      sound = "swiperSlidePaperFlipSound";
      break;
    case "cards":
      sound = "swiperSlidePaperHardSound";
      break;
    default:
      sound = "swiperSlideSound";
      break;
  }
  bus.emit(sound);
}, props.soundDebounce);

watch(auraRequest, () => {
  if (requestId.value !== props.id) return;
  emits("auraResponse", {
    id: props.id,
    slide: props.slides[0],
  });
});

watch(computedHovering, (newHovering) => {
  hover.value = newHovering;
});

watch(computedSlides, (newSlides) => {
  if (swiper.value && newSlides)
    nextTick(() => {
      swiper.value.swiper.update();
    });
});

watch(topDialog, (topDialog) => {
  if (swiper.value && !props.dialog && topDialog)
    swiper.value.swiper.autoplay.stop();
});

watch(hover, (hover) => {
  if (!props.hoverable) return;
  if (!swiper.value) return;
  if (hover && props.hoverable) {
    swiper.value.swiper.autoplay.start();
  } else {
    swiper.value.swiper.autoplay.stop();
  }
});

const swiperParams = {
  autoHeight: props.autoHeight,
  autoplay: props.autoplay,
  centeredSlides: props.centeredSlides,
  centeredSlidesBounds: props.centeredSlidesBounds,
  cssMode: props.cssMode,
  coverflowEffect: {
    slideShadows: false,
  },
  creativeEffect: props.creativeEffect,
  effect: props.effect,
  fade: props.fade,
  freeMode: props.freeMode,
  grabCursor: true,
  keyboard: {
    enabled: props.keyboard,
  },
  loop: props.loop,
  loopAddBlankSlides: props.loopAddBlankSlides,
  loopAdditionalSlides: props.loopAdditionalSlides,
  loopPreventsSliding: props.loopPreventsSliding,
  rewind: props.rewind,
  runCallbacksOnInit: false,
  mousewheel: props.mousewheel,
  navigation: {
    nextEl: nextBtn.value,
    prevEl: prevBtn.value,
  },
  pagination: props.pagination,
  slidesPerView: props.slidesPerView || "auto",
  spaceBetween: props.spaceBetween,
  speed: props.speed,
  zoom: props.zoom,
  on: {
    init(swiper) {
      if (!props.auto) swiper.autoplay.stop();
      updateRealIndex(swiper.realIndex);
    },
    beforeTransitionStart(swiper) {
      useSlideSound();
    },
    progress(s, progress) {
      emits("progress", { s, progress });
    },
    setTransition(s, duration) {
      emits("setTransition", duration);
    },
    slideChange(swiper) {
      handleSlideChange(swiper);
      updateRealIndex(swiper.realIndex);
    },
    update(swiper) {
      updateRealIndex(swiper.realIndex);
    },
  },
};

onMounted(() => {
  Object.assign(swiper.value, swiperParams);
  const customStyles = `
    .swiper { overflow: ${props.overflow ? "visible" : "hidden"};}
    .swiper-3d ::slotted(swiper-slide) {
      transform-style: flat;
    }
  `;
  swiper.value.injectStyles = [customStyles];
  nextTick(() => {
    if (swiper.value) swiper.value.initialize();
    if (nextBtn?.value?.$el && prevBtn?.value?.$el) {
      Object.assign(swiper.value.swiper.params.navigation, {
        nextEl: nextBtn.value.$el,
        prevEl: prevBtn.value.$el,
      });
      swiper.value.swiper.navigation.init();
      swiper.value.swiper.navigation.update();
    }
  });
});
</script>
