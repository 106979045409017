<template>
  <div class="atropos my-atropos overflow-visible" ref="atropos">
    <div class="atropos-scale">
      <div class="atropos-rotate">
        <div class="atropos-inner">
          <image-card
            :height="height ? height : void 0"
            :data="data.pageBanner[0].reference.image"
            :ratio="ratio"
            :to="{ name: 'page', params: { page: data.handle } }"
            :width="width ? width : void 0"
          />
          <content-card-title
            v-if="data.title"
            class="absolute-top-left"
            data-atropos-offset="2"
            margin-left
            :title="data.title"
            :to="{ name: 'page', params: { page: data.handle } }"
          />
          <router-link
            v-if="data.bodySummary"
            :class="textColor"
            :to="{ name: 'page', params: { page: data.handle } }"
          >
            <div
              class="absolute-bottom q-pa-xl text-petite"
              data-atropos-offset="5"
              style="line-height: 1.25"
            >
              <blur-background />
              <div class="q-pb-lg relative-position">
                {{ data.bodySummary }}
              </div>
            </div>
          </router-link>
          <content-card-cta
            class="absolute-bottom-right"
            data-atropos-offset="2"
            margin-right
            :cta="t('page.card.cta.label')"
            :to="{ name: 'page', params: { page: data.handle } }"
            up
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { nextTick, onMounted, ref } from "vue";
import { storeToRefs } from "pinia";
import { useQuasar } from "quasar";
import { i18n } from "src/boot/i18n";
import { useColorStore } from "src/stores/color";
import BlurBackground from "src/components/blur/BlurBackground.vue";
import ContentCardCta from "src/components/content/ContentCardCta.vue";
import ContentCardTitle from "src/components/content/ContentCardTitle.vue";
import ImageCard from "src/components/image/ImageCard.vue";
import Atropos from "atropos";
import "atropos/css";

defineOptions({ name: "PageCard" });

const props = defineProps({
  height: { type: [Boolean, String], default: false },
  data: { type: Object, default: () => {} },
  loading: { type: String, default: "lazy" },
  ratio: { type: Number, default: 0.6667 },
  updateAura: { type: Boolean, default: false },
  width: { type: [Boolean, String], default: false },
});

const $q = useQuasar();
const { t } = i18n.global;
const colorStore = useColorStore();
const { textColor } = storeToRefs(colorStore);
const atropos = ref(null);

onMounted(async () => {
  if ($q.platform.is.mobile) return;
  await nextTick();
  const myAtropos = Atropos({
    el: atropos.value,
    rotateTouch: false,
    rotateXMax: 2.5,
    rotateXMax: 2.5,
  });
});
</script>
