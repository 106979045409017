import gql from "graphql-tag";
import {
  basicProduct,
  productCompleteLook,
  productDetails,
} from "../fragments/product";
import { basicVariant } from "../fragments/variants";
import { imageCard, imageFullscreen } from "../fragments/image";
import { query } from "./query";

export const basicProductById = gql`
  query BasicProductById($id: ID!) {
    product(id: $id) {
      ...BasicProduct
      images(first: 1) {
        edges {
          cursor
          node {
            ...ImageCard
          }
        }
      }
    }
  }
  ${basicProduct}
  ${imageCard}
`;

export const fullProductByHandle = gql`
  query FullProductByHandle($handle: String!) {
    product(handle: $handle) {
      ...BasicProduct
      images(first: 20) {
        edges {
          cursor
          node {
            ...ImageCard
          }
        }
      }
      ...ProductCompleteLook
      ...ProductDetails
      variants(first: 24) {
        edges {
          cursor
          node {
            ...BasicVariant
          }
        }
      }
    }
  }
  ${basicProduct}
  ${basicVariant}
  ${imageCard}
  ${productCompleteLook}
  ${productDetails}
`;

export const productDetailsByID = gql`
  query ProductDetailsByID($id: ID!, $images: Int!) {
    product(id: $id) {
      ...ProductDetails
      images(first: $images) {
        edges {
          cursor
          node {
            ...ImageFullscreen
          }
        }
      }
    }
  }
  ${productDetails}
  ${imageFullscreen}
`;

export const productImagesByID = gql`
  query ProductImagesByID($id: ID!, $images: Int!) {
    product(id: $id) {
      images(first: $images) {
        edges {
          cursor
          node {
            ...ImageFullscreen
          }
        }
      }
    }
  }
  ${imageFullscreen}
`;

export const queryProductByHandle = async (variables) => {
  try {
    const result = await query(fullProductByHandle, variables);
    if (result.data?.product) return result.data.product;
    else return result;
  } catch (error) {
    console.error("Error executing queryProductByHandle:", error);
  }
};
