<template>
  <div class="atropos my-atropos overflow-visible" ref="atropos">
    <div class="atropos-scale">
      <div class="atropos-rotate">
        <div class="atropos-inner">
          <div class="items-center justify-center row">
            <div
              :class="{ 'q-mb-lg': $q.screen.lt.md }"
              class="col-xs-12 col-md-6"
            >
              <div class="q-ma-xl relative-position">
                <router-link :to="toRoute(article)">
                  <q-responsive :ratio="0.6667">
                    <image-card
                      class="overflow-visible shadow-2"
                      :data="{
                        image: urlFor(article.image.asset.url)
                          .width(responsiveWidth)
                          .url(),
                      }"
                    />
                  </q-responsive>
                </router-link>
                <content-card-title
                  class="absolute-top-left"
                  data-atropos-offset="2"
                  margin-left
                  :title="article.title"
                  :to="toRoute(article)"
                />
                <content-card-cta
                  class="absolute-bottom-right"
                  :cta="t('page.card.cta.abbreviated')"
                  data-atropos-offset="2"
                  icon
                  margin-right
                  :to="toRoute()"
                  up
                />
              </div>
            </div>
            <router-link
              class="col-xs-12 col-md-6 flex flex-center relative-position text-no-underline"
              :class="textColor"
              data-atropos-offset="8"
              :to="toRoute()"
            >
              <blur-background class="q-pa-xl" />
              <div class="q-pa-lg q-ma-lg relative-position text-petite">
                {{ article.body }}
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { nextTick, onMounted, ref } from "vue";
import { storeToRefs } from "pinia";
import { useQuasar } from "quasar";
import { i18n } from "src/boot/i18n";
import { urlFor } from "src/boot/sanityUrlFor";
import { useColorStore } from "src/stores/color";
import { useResponsiveImageWidth } from "src/composables/image/useResponsiveImageWidth";
import BlurBackground from "src/components/blur/BlurBackground.vue";
import ContentCardCta from "src/components/content/ContentCardCta.vue";
import ContentCardTitle from "src/components/content/ContentCardTitle.vue";
import ImageCard from "src/components/image/ImageCard.vue";
import Atropos from "atropos";
import "atropos/css";

defineOptions({ name: "BlogArticlesCard" });

const props = defineProps({
  article: { type: Object, default: () => {} },
});

const $q = useQuasar();
const { t } = i18n.global;
const colorStore = useColorStore();
const { textColor } = storeToRefs(colorStore);
const atropos = ref(null);
const mdWidth = 476;
const responsiveWidth = useResponsiveImageWidth(mdWidth);

const toRoute = () => {
  return {
    name: "blogArticle",
    params: { article: props.article.handle, blog: props.article.blog },
  };
};

onMounted(async () => {
  await nextTick();
  if ($q.platform.is.mobile) return;
  const myAtropos = Atropos({
    el: atropos.value,
    rotateTouch: false,
    rotateXMax: 2.5,
    rotateXMax: 2.5,
    shadow: true,
  });
});
</script>
