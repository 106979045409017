<template>
  <component
    :class="{ 'card-title-left': afterContent }"
    class="bg-transparent items-center justify-start q-pa-sm row text-no-underline text-shadow"
    :style="`${padding ? 'top: 124px; ' : 'top: 0px; '}${
      marginLeft ? 'margin-left: -16px;' : ''
    }`"
    :is="to ? 'router-link' : 'div'"
    :to="to || undefined"
  >
    <blur-background blur="md" class="q-ma-sm" />
    <blur-mask mask="r" />
    <div :class="[textColor, ...classes]" class="q-pl-sm relative-position">
      <span
        v-if="title"
        :class="
          $q.screen.gt.sm && gridSize > 4
            ? 'ellipsis-3-lines'
            : 'ellipsis-2-lines'
        "
        style="line-height: 1.3125"
        >{{ title }}</span
      >
      <slot />
    </div>
  </component>
</template>

<script setup>
import { storeToRefs } from "pinia";
import { useColorStore } from "src/stores/color";
import { useLayoutStore } from "src/stores/layout";
import BlurBackground from "src/components/blur/BlurBackground.vue";
import BlurMask from "src/components/blur/BlurMask.vue";

defineOptions({ name: "ContentCardTitle" });

const props = defineProps({
  afterContent: { type: Boolean, default: true },
  classes: { type: Array, default: () => ["text-subtitle1"] },
  padding: { type: Boolean, default: false },
  marginLeft: { type: Boolean, default: false },
  title: { type: [String, Boolean], default: false },
  to: { type: [Object, Boolean], default: false },
});

const colorStore = useColorStore();
const { textColor } = storeToRefs(colorStore);
const layoutStore = useLayoutStore();
const { gridSize } = storeToRefs(layoutStore);
</script>
