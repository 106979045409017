<template>
  <div
    class="atropos my-atropos overflow-visible"
    ref="atropos"
    :style="slideStyle"
  >
    <div class="atropos-scale">
      <div class="atropos-rotate">
        <div class="atropos-inner">
          <div v-if="data?.image?.asset?.url">
            <image-card
              :alt="data.image.asset.altText"
              class="full-width overflow-visible shadow-2"
              :data="{
                image: urlFor(data.image.asset.url)
                  .width(responsiveWidth)
                  .url(),
              }"
              :to="toRouteFallback || undefined"
              :toAlt="`Shop ${computedTitle}`"
            />
            <content-card-title
              v-if="data.showTitle"
              class="absolute-top-left"
              data-atropos-offset="2"
              margin-left
              :padding="padding"
              :title="computedTitle"
              :to="toRouteFallback || false"
            />
            <content-card-cta
              v-if="data.showCta"
              class="absolute-bottom-right"
              data-atropos-offset="2"
              margin-right
              :padding="padding"
              :cta="computedCta"
              :to="toRouteFallback || false"
              :up="up"
            />

            <div
              v-if="
                active && data.productHotspots && data.productHotspots.length
              "
              class="bg-transparent fit"
            >
              <hotspot-component
                v-for="(hotspot, index) in data.productHotspots"
                data-atropos-offset="15"
                :key="hotspot._key"
                :hotspot="hotspot"
                :image="data.image"
                :index="index + 1"
                :productData="
                  productData[
                    hotspot.productWithVariant?.product?.store?.slug?.current
                  ]
                "
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, nextTick, onMounted, ref } from "vue";
import { useQueries } from "@tanstack/vue-query";
import { useQuasar } from "quasar";
import { queryProductByHandle } from "src/api/shopify/queries/product";
import { urlFor } from "src/boot/sanityUrlFor";
import { useInternalLink } from "src/composables/link/useInternalLink";
import { useResponsiveImageWidth } from "src/composables/image/useResponsiveImageWidth";
import ContentCardCta from "src/components/content/ContentCardCta.vue";
import ContentCardTitle from "src/components/content/ContentCardTitle.vue";
import HotspotComponent from "src/components/hotspot/HotspotComponent.vue";
import ImageCard from "src/components/image/ImageCard.vue";
import Atropos from "atropos";
import "atropos/css";

defineOptions({ name: "HotspotHero" });

const props = defineProps({
  active: { type: Boolean, default: false },
  data: { type: Object, required: true },
  padding: { type: Boolean, default: false },
  slideStyle: { type: Object, default: () => {} },
  up: { type: Boolean, default: true },
});

const $q = useQuasar();
const { getInternalLinkData } = useInternalLink();
const { title, toRoute } = getInternalLinkData(props.data.linkInternal);
const mdWidth = 476;
const responsiveWidth = useResponsiveImageWidth(mdWidth);
const firstProduct = computed(() => {
  const { productHotspots } = props.data;
  return productHotspots?.length > 0 &&
    productHotspots[0]?.productWithVariant?.product?.store
    ? productHotspots[0].productWithVariant.product.store
    : null;
});

const atropos = ref(null);

const toRouteFallback = computed(() => {
  const fallback = firstProduct.value
    ? { name: "product", params: { product: firstProduct.value.slug.current } }
    : null;
  return toRoute || fallback;
});

const computedTitle = computed(() =>
  props.data.title
    ? props.data.title
    : toRoute?.name === "collection"
    ? title
    : firstProduct.value
    ? firstProduct.value.title
    : null
);
const computedCta = computed(() =>
  props.data.cta
    ? props.data.cta
    : toRoute?.name === "collection"
    ? "Shop Now"
    : firstProduct.value
    ? `$${firstProduct.value.priceRange.minVariantPrice}`
    : null
);

const handles = computed(
  () =>
    props.data.productHotspots
      ?.map(
        (hotspot) => hotspot.productWithVariant?.product?.store?.slug?.current
      )
      .filter((handle) => handle !== null) || []
);

const queries = useQueries({
  queries: handles.value.map((handle) => ({
    queryKey: ["productData", handle],
    queryFn: () => queryProductByHandle({ handle }),
    enabled: !!handle,
  })),
});

const productData = computed(() => {
  const data = {};
  queries.value.forEach((query) => {
    if (query.data) {
      const handle = query.data.handle;
      data[handle] = query.data;
    }
  });
  return data;
});

onMounted(async () => {
  if ($q.platform.is.mobile) return;
  await nextTick();
  const myAtropos = Atropos({
    el: atropos.value,
    rotateTouch: false,
    rotateXMax: 2.5,
    rotateXMax: 2.5,
  });
});
</script>
