<template>
  <slot :product="product" :variants="variants" />
</template>

<script setup>
import { computed } from "vue";

defineOptions({ name: "ProductVariant" });
const props = defineProps({
  product: { type: Object, default: () => {} },
});

const variantSizeData = computed(() => {
  return props.product?.sizeGuideList?.value
    ? JSON.parse(props.product.sizeGuideList.value)
    : null;
});

const variantsSizeGuide = computed(() => {
  let guide = [];
  if (variantSizeData.value)
    variantSizeData.value.forEach((size) => {
      const data = size.split("--");
      guide.push({ size: data[0], guide: data[1] });
    });
  return guide;
});

const variants = computed(() => {
  let i = 0;
  let options = [];
  if (props.product?.variants?.edges) {
    props.product.variants.edges.forEach((option) => {
      if (option.node.availableForSale) {
        let variant = {};
        variant.cursor = option.cursor;
        variant.node = option.node;
        variant.size = variantsSizeGuide.value[i]?.guide || null;
        options.push(variant);
      }
      i++;
    });
  }
  return options;
});
</script>
