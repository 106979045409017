<template>
  <transition
    appear
    enter-active-class="animated slideInUp"
    leave-active-class="animated slideOutDown"
  >
    <q-btn
      v-if="hover"
      class="no-stroke"
      :color="color"
      flat
      :icon="sharpVisibility"
      :size="size"
      style="opacity: 0.4"
      @click="onClick()"
    >
    </q-btn>
  </transition>
</template>

<script setup>
import { useDialogStore } from "src/stores/dialog";
import { sharpVisibility } from "@quasar/extras/material-icons-sharp";

defineOptions({ name: "ProductPreviewBtn" });

const props = defineProps({
  color: { type: String, default: "secondary" },
  hover: { type: Boolean, default: false },
  product: { type: Object, default: () => {} },
  size: { type: String, default: "lg" },
});

const dialogStore = useDialogStore();

const onClick = () => {
  dialogStore.show("product", { product: props.product });
};
</script>
