<template>
  <div class="atropos my-atropos overflow-visible" ref="atropos">
    <div class="atropos-scale">
      <div class="atropos-rotate">
        <div class="atropos-inner">
          <image-card
            :active="active"
            :alt="alt"
            :classes="classes"
            :data="data"
            :height="height"
            :get-color="getColor"
            :index="index"
            :loading="loading"
            :position="position"
            :ratio="ratio"
            :shadow="shadow"
            :to="to"
            :to-alt="toAlt"
            :width="width"
            :zoom-enabled="zoomEnabled"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { nextTick, onMounted, ref } from "vue";
import { useQuasar } from "quasar";
import ImageCard from "./ImageCard.vue";
import Atropos from "atropos";
import "atropos/css";

defineOptions({ name: "ImageAtroposCard" });

const props = defineProps({
  active: { type: Boolean, default: true },
  alt: { type: String, default: "image" },
  classes: { type: Array, default: () => ["absolute-full", "flex"] },
  data: { type: Object, default: () => ({}) },
  height: { type: [Boolean, String], default: false },
  getColor: { type: Boolean, default: false },
  index: { type: Number, default: 0 },
  loading: { type: String, default: "lazy" },
  position: { type: String, default: "50% 50%" },
  ratio: { type: Number, default: 0.6667 },
  shadow: { type: [Boolean, Number], default: 1 },
  to: { type: [Boolean, Object], default: false },
  toAlt: { type: String, default: "link to image" },
  width: { type: [Boolean, String], default: false },
  zoomEnabled: { type: Boolean, default: false },
});

const $q = useQuasar();
const atropos = ref(null);

onMounted(async () => {
  if ($q.platform.is.mobile) return;
  await nextTick();
  const myAtropos = Atropos({
    el: atropos.value,
    rotateTouch: false,
    rotateXMax: 2.5,
    rotateXMax: 2.5,
  });
});
</script>
