<template>
  <div
    class="atropos my-atropos overflow-visible"
    ref="atropos"
    @mouseenter="onEnter()"
    @mouseleave="onLeave()"
  >
    <div class="atropos-scale">
      <div class="atropos-rotate">
        <div class="atropos-inner">
          <image-card
            :height="height ? height : void 0"
            :data="data.image"
            :ratio="ratio"
            :to="toRoute(data, $route)"
            :width="width ? width : void 0"
          />
          <router-link
            v-if="!$q.platform.is.mobile && firstHover"
            :to="toRoute(data, $route)"
            class="absolute-full"
          >
            <div class="absolute-full">
              <swiper-component
                :autoplay="{
                  delay: 800,
                  disableOnInteraction: false,
                  pauseOnMouseEnter: false,
                }"
                hoverable
                :hovering="hover"
                :navigation="false"
                :slides="images"
                @slide-change="(slide) => onSlideChange(slide)"
              />
            </div>
          </router-link>
          <content-card-title
            v-if="data.title"
            class="absolute-top-left"
            data-atropos-offset="2"
            margin-left
            :title="data.title"
            :to="toRoute(data, $route)"
          />
          <content-card-cta
            class="absolute-bottom-right"
            data-atropos-offset="2"
            margin-right
            :cta="t('cta.cardLabel')"
            :to="toRoute(data, $route)"
            up
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, nextTick, onMounted, ref } from "vue";
import { debounce, useQuasar } from "quasar";
import { i18n } from "src/boot/i18n";
import { useImageFinder } from "src/composables/image/useImageFinder";
import ContentCardTitle from "src/components/content/ContentCardTitle.vue";
import ContentCardCta from "src/components/content/ContentCardCta.vue";
import ImageCard from "src/components/image/ImageCard.vue";
import SwiperComponent from "src/components/swiper/SwiperComponent.vue";
import Atropos from "atropos";
import "atropos/css";

const HOVER_DELAY = 1000;
const LEAVE_DELAY = 2000;

defineOptions({ name: "CollectionCard" });

const emits = defineEmits(["slideChange"]);

const props = defineProps({
  height: { type: [Boolean, String], default: false },
  data: { type: Object, default: () => {} },
  loading: { type: String, default: "lazy" },
  ratio: { type: Number, default: 0.6667 },
  updateAura: { type: Boolean, default: false },
  width: { type: [Boolean, String], default: false },
});

const $q = useQuasar();
const { t } = i18n.global;
const atropos = ref(null);
const firstHover = ref(false);
const hover = ref(false);
const hoverTimeout = ref(null);
const { findImages } = useImageFinder();
const images = computed(() => findImages(props.data));

const onEnter = () => {
  if ($q.platform.is.mobile) return;
  hoverTimeout.value = setTimeout(() => {
    if (!firstHover.value) firstHover.value = true;
    hover.value = true;
  }, HOVER_DELAY);
};

const onLeave = debounce(() => {
  if ($q.platform.is.mobile) return;
  clearTimeout(hoverTimeout.value);
  hover.value = false;
}, LEAVE_DELAY);

const onSlideChange = (slide) => {
  emits("slideChange", slide);
};

const toRoute = (collection, route) => {
  let to = {};
  switch (route.name) {
    default:
      to = {
        name: "landingCollection",
        params: {
          landing: route.params.landing,
          collection: collection.handle,
        },
      };
      break;
  }
  return to;
};

onMounted(async () => {
  if ($q.platform.is.mobile) return;
  await nextTick();
  const myAtropos = Atropos({
    el: atropos.value,
    rotateTouch: false,
    rotateXMax: 2.5,
    rotateXMax: 2.5,
  });
});
</script>
