export function useFormatPrice() {
  const formatPrice = (variant) => {
    const formattedPrice = (price) => parseFloat(price).toFixed(0);

    if (!variant) {
      return {
        price: "",
        compareAtPrice: "",
        isOnSale: false,
        isUnavailable: false,
      };
    }

    const price = formattedPrice(variant.price.amount);

    let compareAtPrice;
    if (
      variant.compareAtPrice?.amount &&
      variant.compareAtPrice?.amount !== "0.0"
    ) {
      compareAtPrice = formattedPrice(variant.compareAtPrice.amount);
    }

    return {
      price,
      compareAtPrice,
      isOnSale: !!compareAtPrice,
      isUnavailable: variant.quantityAvailable === 0,
    };
  };

  return {
    formatPrice,
  };
}
