<template>
  <q-no-ssr class="cursor-pointer" :style="getStyle()">
    <q-btn round size="lg" @click="onClick()">
      <q-avatar
        v-if="productData"
        color="primary"
        :size="$q.screen.gt.sm ? 'xs' : 'xs'"
        :class="textColor"
      >
        {{ index }}
        <q-tooltip
          v-if="!hotspot.showProduct || !$q.platform.is.mobile"
          class="bg-transparent overflow-visible"
          :class="textColor"
          @before-show="onBeforeShow()"
        >
          <q-list dense>
            <blur-mask :mask="false" />
            <q-item :style="hotspot.showProduct ? 'padding-left: 0px' : null">
              <q-item-section v-if="hotspot.showProduct" avatar>
                <q-avatar square>
                  <q-img :src="croppedImageUrl" :ratio="1">
                    <template #loading>
                      <q-spinner-tail color="primary" size="42%" />
                    </template>
                  </q-img>
                </q-avatar>
              </q-item-section>
              <q-item-section>
                <div
                  v-if="!hotspot.showProduct"
                  :class="showPrice ? 'text-caption' : 'text-small'"
                >
                  {{ hotspot.text }}
                </div>
                <div v-else class="items-start column text-small">
                  <div>
                    {{ hotspot?.productWithVariant?.product?.store?.title }}
                  </div>
                  <div class="text-caption">
                    ${{
                      hotspot?.productWithVariant?.product?.store?.priceRange
                        ?.maxVariantPrice ?? "N/A"
                    }}
                  </div>
                </div>
              </q-item-section>
            </q-item>
          </q-list>
        </q-tooltip>
        <q-menu v-if="$q.platform.is.mobile" @before-show="onBeforeShow()">
          <q-list dense>
            <blur-mask :mask="false" />
            <q-item :style="hotspot.showProduct ? 'padding-left: 0px' : null">
              <q-item-section v-if="hotspot.showProduct" avatar>
                <q-avatar square>
                  <q-img :src="croppedImageUrl" :ratio="1">
                    <template #loading>
                      <q-spinner-tail color="primary" size="42%" />
                    </template>
                  </q-img>
                </q-avatar>
              </q-item-section>
              <q-item-section>
                <div
                  v-if="!hotspot.showProduct"
                  :class="showPrice ? 'text-caption' : 'text-small'"
                >
                  {{ hotspot.text }}
                </div>
                <div v-else class="items-start column text-small">
                  <div>
                    {{ hotspot?.productWithVariant?.product?.store?.title }}
                  </div>
                  <div class="text-caption">
                    ${{
                      hotspot?.productWithVariant?.product?.store?.priceRange
                        ?.maxVariantPrice ?? "N/A"
                    }}
                  </div>
                </div>
              </q-item-section>
            </q-item>
          </q-list>
        </q-menu>
      </q-avatar>
    </q-btn>
  </q-no-ssr>
</template>

<script setup>
import { computed, inject } from "vue";
import { storeToRefs } from "pinia";
import { useQuasar } from "quasar";
import { useColorStore } from "src/stores/color";
import { useDialogStore } from "src/stores/dialog";
import BlurMask from "src/components/blur/BlurMask.vue";

defineOptions({ name: "HotspotComponent" });

const $q = useQuasar();
const bus = inject("bus");
const colorStore = useColorStore();
const { textColor } = storeToRefs(colorStore);
const dialogStore = useDialogStore();

const props = defineProps({
  canEmit: { type: Boolean, default: true },
  hotspot: { type: Object, required: true },
  image: { type: Object, default: () => ({}) },
  index: { type: Number, default: 1 },
  productData: { type: Object, default: () => ({}) },
  showPrice: { type: Boolean, default: false },
});

const croppedImageUrl = computed(() => {
  return (
    props.hotspot?.productWithVariant?.product?.store?.previewImageUrl ?? ""
  );
});

const getStyle = () => {
  if (!props.hotspot) return;
  return {
    position: "absolute",
    top: `${props.hotspot.y}%`,
    left: `${props.hotspot.x}%`,
    transform: "translate(-50%, -50%)",
  };
};

const onBeforeShow = () => {
  bus.emit("shimmerChimeSound");
};

const onClick = () => {
  if (!props.hotspot.productWithVariant?.product?.store) return;
  if (!props.productData) return;
  dialogStore.show("product", { product: props.productData });
};
</script>
