<template>
  <component
    :class="
      afterContent ? (!up ? 'card-title-right' : 'card-title-right-up') : null
    "
    class="bg-transparent items-center justify-end q-pa-sm row text-no-underline"
    :style="`${padding ? 'bottom: 54px; ' : 'bottom: 0px; '}${
      marginRight ? 'margin-right: -16px;' : ''
    }`"
    :is="to ? 'router-link' : 'div'"
    :to="to || undefined"
  >
    <blur-background blur="md" class="q-ma-sm" />
    <blur-mask mask="l" />
    <div
      v-if="cta"
      :class="[textColor, ...classes]"
      class="q-pr-sm relative-position row text-right"
    >
      {{ cta }}
      <q-icon
        v-if="icon"
        class="on-right stroke"
        :name="t('icons.handRight')"
        size="sm"
      />
    </div>
    <slot v-else :can-emit="canEmit" />
  </component>
</template>

<script setup>
import { storeToRefs } from "pinia";
import { i18n } from "src/boot/i18n";
import { useColorStore } from "src/stores/color";
import BlurBackground from "src/components/blur/BlurBackground.vue";
import BlurMask from "src/components/blur/BlurMask.vue";

defineOptions({ name: "ContentCardCta" });

const props = defineProps({
  afterContent: { type: Boolean, default: true },
  canEmit: { type: Boolean, default: true },
  classes: { type: Array, default: () => ["text-subtitle1"] },
  icon: { type: Boolean, default: false },
  padding: { type: Boolean, default: false },
  marginRight: { type: Boolean, default: false },
  cta: { type: [String, Boolean], default: false },
  to: { type: [Object, Boolean], default: false },
  up: { type: Boolean, default: false },
});

const { t } = i18n.global;
const colorStore = useColorStore();
const { textColor } = storeToRefs(colorStore);
</script>
