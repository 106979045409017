<template>
  <div class="atropos my-atropos overflow-visible" ref="atropos">
    <div class="atropos-scale">
      <div class="atropos-rotate">
        <div class="atropos-inner">
          <image-card
            :height="height ? height : null"
            :data="data.image"
            :ratio="ratio"
            :to="{
              name: 'blogArticle',
              params: { article: data.handle, blog },
            }"
            :width="width ? width : null"
          />
          <content-card-title
            :classes="['text-subtitle1']"
            class="absolute-top-left"
            data-atropos-offset="2"
            margin-left
            style="width: 67%"
            :title="data.title"
            :to="{
              name: 'blogArticle',
              params: { article: data.handle, blog },
            }"
          />
          <router-link
            v-if="data.excerpt"
            :class="textColor"
            :to="{
              name: 'blogArticle',
              params: { article: data.handle, blog },
            }"
          >
            <div
              class="absolute-bottom q-pa-xl text-petite"
              data-atropos-offset="5"
              style="line-height: 1.25"
            >
              <blur-background />
              <div class="q-pb-lg relative-position">{{ data.excerpt }}</div>
            </div>
          </router-link>
          <content-card-cta
            class="absolute-bottom-right"
            :classes="['text-caption']"
            :cta="
              drawerView
                ? t('page.card.cta.abbreviated')
                : t('page.card.cta.label')
            "
            data-atropos-offset="2"
            :to="{
              name: 'blogArticle',
              params: { article: data.handle, blog },
            }"
            icon
            margin-right
            up
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, nextTick, onMounted, ref } from "vue";
import { storeToRefs } from "pinia";
import { useQuasar } from "quasar";
import { i18n } from "src/boot/i18n";
import { useColorStore } from "src/stores/color";
import BlurBackground from "src/components/blur/BlurBackground.vue";
import ContentCardCta from "src/components/content/ContentCardCta.vue";
import ContentCardTitle from "src/components/content/ContentCardTitle.vue";
import ImageCard from "src/components/image/ImageCard.vue";
import Atropos from "atropos";
import "atropos/css";

defineOptions({ name: "ArticleCard" });

const props = defineProps({
  blog: { type: String, default: "news" },
  height: { type: [Boolean, String], default: false },
  data: { type: Object, default: () => {} },
  loading: { type: String, default: "lazy" },
  ratio: { type: Number, default: 0.6667 },
  updateAura: { type: Boolean, default: false },
  view: { type: String, default: "default" },
  width: { type: [Boolean, String], default: false },
});

const $q = useQuasar();
const { t } = i18n.global;
const colorStore = useColorStore();
const { textColor } = storeToRefs(colorStore);
const atropos = ref(null);
const drawerView = computed(() => props.view === "drawer");

onMounted(async () => {
  if ($q.platform.is.mobile) return;
  await nextTick();
  const myAtropos = Atropos({
    el: atropos.value,
    rotateTouch: false,
    rotateXMax: 2.5,
    rotateXMax: 2.5,
  });
});
</script>
