<template>
  <q-responsive
    :ratio="ratio"
    :style="width ? `width: ${width}` : height ? `height: ${height}` : null"
  >
    <component
      :active="active"
      :article="article"
      :alt="alt"
      :blog="blog"
      :can-emit="canEmit"
      :data="content.node || content"
      :height="height"
      :index="index"
      :is="component(type)"
      :loading="loading"
      :padding="padding"
      :ratio="ratio"
      :slide-style="slideStyle"
      :to="to"
      :to-alt="toAlt"
      :update-aura="updateAura"
      :view="view"
      :zoom-enabled="zoomEnabled"
      @slide-change="(image) => onSlideChange(image)"
    />
  </q-responsive>
</template>

<script setup>
import { shallowRef } from "vue";
import ArticleCard from "src/components/article/ArticleCard.vue";
import BlogArticlesCard from "src/components/blog/BlogArticlesCard.vue";
import BlogCard from "src/components/blog/BlogCard.vue";
import CollectionCard from "src/components/collection/CollectionCard.vue";
import GiftCardVariants from "src/components/giftCard/GiftCardVariants.vue";
import ImageAtroposCard from "../image/ImageAtroposCard.vue";
import HotspotHero from "src/components/hotspot/HotspotHero.vue";
import PageCard from "src/components/page/PageCard.vue";
import ProductCard from "src/components/product/ProductCard.vue";

defineOptions({ name: "ContentCard" });
const props = defineProps({
  active: { type: Boolean, default: true },
  alt: { type: String, default: "image" },
  article: { type: Object, default: () => {} },
  blog: { type: String, default: "news" },
  canEmit: { type: Boolean, default: false },
  content: { type: Object, default: () => {} },
  height: { type: [Boolean, String], default: false },
  index: { type: Number, default: 0 },
  loading: { type: String, default: "lazy" },
  padding: { type: Boolean, defaut: false },
  ratio: { type: Number, default: 0.6667 },
  slideStyle: { type: Object, default: () => {} },
  to: { type: [Boolean, Object], default: false },
  toAlt: { type: String, default: "link to image" },
  type: { type: String, default: "image" },
  updateAura: { type: Boolean, default: false },
  view: { type: String, default: "default" },
  width: { type: [Boolean, String], default: false },
  zoomEnabled: { type: Boolean, defaut: false },
});

const emits = defineEmits(["slideChange"]);

const cardComponent = shallowRef(null);

const component = (type) => {
  switch (type) {
    case "Article":
    case "ArticleEdge":
      cardComponent.value = ArticleCard;
      break;
    case "Blog":
      cardComponent.value = BlogCard;
      break;
    case "BlogArticlesCard":
      cardComponent.value = BlogArticlesCard;
      break;
    case "Collection":
      cardComponent.value = CollectionCard;
      break;
    case "GiftCard":
    case "ProductVariant":
      cardComponent.value = GiftCardVariants;
      break;
    case "Product":
    case "ProductConnection":
      cardComponent.value = ProductCard;
      break;
    case "HotspotHero":
      cardComponent.value = HotspotHero;
      break;
    case "Page":
      cardComponent.value = PageCard;
      break;
    default:
      cardComponent.value = ImageAtroposCard;
      break;
  }
  return cardComponent.value;
};

const onSlideChange = (image) => {
  if (!props.active) return;
  emits("slideChange", image);
};
</script>
