<template>
  <div class="q-pa-xs">
    <div
      :class="classes ? classes : void 0"
      class="ellipsis-3-lines q-pr-xl"
      style="line-height: 1.3125"
    >
      <blur-mask mask="r" />
      <span :class="textColor" class="relative-position">
        {{ label }}
      </span>
    </div>
  </div>
</template>

<script setup>
import { storeToRefs } from "pinia";
import { useColorStore } from "src/stores/color";
import BlurMask from "src/components/blur/BlurMask.vue";

defineOptions({ name: "TextLabel" });

const props = defineProps({
  classes: { type: [Boolean, String, Array], default: false },
  label: { type: String, default: "Label" },
});

const colorStore = useColorStore();
const { textColor } = storeToRefs(colorStore);
</script>
