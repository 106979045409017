<template>
  <div class="relative-position">
    <q-img
      v-if="background"
      class="absolute-full"
      :src="computedAuraBackground"
    >
      <template #loading>
        <q-spinner-tail color="dark" size="50%" />
      </template>
    </q-img>
    <q-img
      v-if="showing"
      :height="height ? height : void 0"
      :loading="loading"
      :ratio="ratio"
      :placeholder-src="placeholder ? computedAuraPlaceholder : void 0"
      sizes="(max-width: 400px) 400w, (min-width: 400px) and (max-width: 800px) 800w, (min-width: 800px and max-width: 1200px) 1200w, (min-width: 1200px) 1600w"
      :srcset="`${image.node.md} 400w, ${image.node.lg} 800w, ${image.node.xl} 1200w, ${image.node.xx} 1600w`"
      :width="width ? width : void 0"
      @load="onLoad()"
    >
      <template #loading>
        <q-spinner-tail color="primary" size="42%" />
      </template>
    </q-img>
  </div>
</template>

<script setup>
import { computed, ref, inject, onMounted } from "vue";
import { getAverageColor } from "src/composables/image/useImageColor";
import { useAura } from "src/composables/image/useAura";
import { watch } from "vue";

defineOptions({ name: "ProductImage" });

const emits = defineEmits(["colorValue"]);

const props = defineProps({
  api: { type: String, default: "aura" },
  background: { type: Boolean, default: false },
  placeholder: { type: Boolean, default: false },
  height: { type: [Boolean, String], default: false },
  image: { type: Object, default: () => ({}) },
  loading: { type: String, default: "lazy" },
  ratio: { type: Number, default: 0.6667 },
  width: { type: [Boolean, String], default: false },
});

const { computedAura: computedAuraPlaceholder, fetchAura } = useAura(
  props,
  emits
);

const bus = inject("bus");
const showing = ref(!props.placeholder);
const computedAuraBackground = computed(() => computedAuraPlaceholder.value);

const onLoad = () => {
  bus.emit("imageLoadSound");
  getAverageColor(props.image.node.aura, (color) => {
    emits("colorValue", color);
  });
};

watch(computedAuraPlaceholder, (newAura) => {
  if (newAura) showing.value = true;
});

onMounted(() => {
  if (props.background || props.placeholder) fetchAura();
});
</script>
